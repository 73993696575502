<template>
  <div>
    <label
      v-if="!checkboxVertical"
      class="checkbox is-unselectable"
    >
      <input
        :name="name"
        type="checkbox"
        :checked="value"
        class="hidden"
        @input="onChange"
        :disabled="disabled"
      />
      <span
        class="input"
        :class="[inputAdditionClass, value ? inputCheckedClass : '']"
      >

        <span
          class="icon"
          :class="iconAdditionClass"
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
          >
            <path
              fill="currentColor"
              d="M0.252,12.214c-0.341,-0.44 -0.219,-0.816 0.025,-1.219l1.824,-2.333c0.37,-0.369 0.85,-0.381 1.219,-0.012l6.59,6.199c0.185,0.185 0.462,0.185 0.646,0l9.812,-10.435c0.369,-0.369 0.923,-0.369 1.292,0l2.046,2.135c0.37,0.37 0.32,0.973 0,1.293l-12.889,13.189c-0.185,0.184 -0.37,0.277 -0.647,0.277c-0.276,0 -0.461,-0.093 -0.646,-0.277l-9.272,-8.817Z"
              style="fill-rule:nonzero;"
            />
          </svg>
        </span>
      </span>
      <slot>{{ label }}</slot>
    </label>

    <!-- Vertical checkbox -->
    <label
      v-if="checkboxVertical"
      class="label-top has-margin-bottom"
    >
      <span class="has-margin-bottom">{{label}}</span>
      <input
        :name="name"
        type="checkbox"
        :checked="value"
        class="hidden"
        @input="onChange"
        :disabled="disabled"
      />
      <span
        class="input"
        :class="[inputAdditionClass, value ? inputCheckedClass : '']"
      >

        <span
          class="icon"
          :class="iconAdditionClass"
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
          >
            <path
              fill="currentColor"
              d="M0.252,12.214c-0.341,-0.44 -0.219,-0.816 0.025,-1.219l1.824,-2.333c0.37,-0.369 0.85,-0.381 1.219,-0.012l6.59,6.199c0.185,0.185 0.462,0.185 0.646,0l9.812,-10.435c0.369,-0.369 0.923,-0.369 1.292,0l2.046,2.135c0.37,0.37 0.32,0.973 0,1.293l-12.889,13.189c-0.185,0.184 -0.37,0.277 -0.647,0.277c-0.276,0 -0.461,-0.093 -0.646,-0.277l-9.272,-8.817Z"
              style="fill-rule:nonzero;"
            />
          </svg>
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import { i18n } from "@/main.js";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
      // note: 'Whether the input field is disabled'
      note: i18n.t('Components.UI.Form.BaseInputFieldController.Disabled_Note')
    },
    inputAdditionClass: {
      type: String,
      default: ''
    },
    inputCheckedClass: {
      type: String,
      default: 'is-success'
    },
    iconAdditionClass: {
      type: String,
      default: 'has-text-white'
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    checkboxVertical: {
      type: Boolean,
      default: false

    }
  },

  methods: {
    onChange(e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";
.label-top {
  color: #363636;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  .input {
    width: auto;
    padding: 0px;
    margin-right: 5px;
    height: auto;
    align-self: flex-start;
    .icon {
      padding: 2px;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    outline: 0;
    user-select: none;
    display: inline-block;
    position: absolute;
    opacity: 0;
    &:checked ~ .input {
      &.is-danger {
        border-color: $red;
        background-color: $red;
      }
      &.is-success {
        border-color: $green;
        background-color: $green;
      }
      &.is-warning {
        border-color: $yellow;
        background-color: $yellow;
      }
    }
  }
}

label {
  display: inline-flex;
  align-items: center;

  .input {
    width: auto;
    padding: 0px;
    margin-right: 5px;
    height: auto;
    .icon {
      padding: 2px;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    outline: 0;
    user-select: none;
    display: inline-block;
    position: absolute;
    opacity: 0;
    &:checked ~ .input {
      &.is-danger {
        border-color: $red;
        background-color: $red;
      }
      &.is-success {
        border-color: $green;
        background-color: $green;
      }
      &.is-warning {
        border-color: $yellow;
        background-color: $yellow;
      }
    }
  }
}
</style>
