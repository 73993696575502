<template>
  <Modal
    :modalTitle="$t('Components.Article.ArticleDetail.ModalDeleteArticleSource.Modal_Title')"
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :successMessage="$t('Components.Article.ArticleDetail.ModalDeleteArticleSource.Message_succesDeleting')"
    :errorMessage="$t('Components.Article.ArticleDetail.ModalDeleteArticleSource.Message_ErrorDeleting')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteLink"
    :showModal="showModal"
  >
    <template v-slot:content>
      <div class="delete-content-container">
        <div class="mb-4 is-size-5 has-text-centered">
          {{
          $t('Components.Article.ArticleDetail.ModalDeleteArticleSource.Message_AreYouSure', {
            name: source.Text,
          })
        }}
        </div>

        <div class="has-text-centered">
          <Checkbox
            v-model="isDeleteConfirmed"
            :label="$t('General.Text_YesSure')"
            @click.ctrl.left.exact="deleteLink"
            class="is-inline-block"
          />
        </div>

      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Modal from '@/components/UI/Components/Modal.vue'
import Checkbox from '@/components/UI/Form/Checkbox.vue'
import articleProvider from '../../providers/article'

export default {
  name: 'ModalDeleteArticleSource',
  components: {
    Modal,
    Checkbox
  },

  props: {
    articleId: {
      type: Number,
      required: true
    },
    source: {
      type: Object,
      required: true,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('articleStore', ['article']),
  },

  created() { },

  methods: {
    ...mapMutations('articleStore', ['setArticle']),

    async deleteLink() {
      if (this.isDeleting) { return }
      this.isDeleting = true

      try {
        await articleProvider.methods.deleteArticleLink(this.articleId, this.source.Id)
        this.isDeletingSuccess = true
        this.$emit('updated', this.source.Id)
        let t = setTimeout(() => {
          this.onClickCancel()
          clearTimeout(t)
        }, 1500);
      } catch {
        this.isDeleting = false
        this.isDeletingError = true
      }
    },
  },
}
</script>
